import React, { useCallback, useRef, useState } from 'react';

import { read, utils } from 'xlsx';

import {
  ColumnFile,
  Container,
  ContentFile,
  DivButtonSubmit,
  HeaderFile,
} from './styles';
import api from '../../../../services/api';

import Button from '../../../../components/Button';
import BackButton from '../../../../components/BackButton';
import { useToast } from '../../../../hooks/toast';

interface CategorizedAnswer {
  ResultId: string;
  QuestionId: string;
  QuestionText: string;
  Answer: string;
  Categories: string;
}

const ImportFile: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { addToast } = useToast();
  const [isSeelectedFile, setIsSelectedFile] = useState(false);
  const [showButtonSubmit, setShowButtonSubmit] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [categorizedAnswers, setCategorizedAnswers] =
    useState<CategorizedAnswer[]>();

  async function ValidationDataFile(
    data: CategorizedAnswer[],
  ): Promise<string[]> {
    const errorsFromFile: string[] = [];

    data.forEach((element: CategorizedAnswer) => {
      if (!element.ResultId) {
        errorsFromFile.push('ResultId is required');
      }
      if (!element.QuestionId) {
        errorsFromFile.push('QuestionId is required');
      }
    });

    return errorsFromFile;
  }

  const onChange = async (e: any): Promise<void> => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();

    const wb = read(f);
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const data = utils.sheet_to_json<CategorizedAnswer>(ws); // generate objects
    setIsSelectedFile(true);

    const errorsFromFile = await ValidationDataFile(data);
    setErrors(errorsFromFile);
    setShowButtonSubmit(false);
    setCategorizedAnswers(data);

    if (errorsFromFile.length === 0) {
      setShowButtonSubmit(true);
    }
  };

  const handleImportSubmit = useCallback(async () => {
    setShowButtonSubmit(!showButtonSubmit);

    try {
      if (!categorizedAnswers || categorizedAnswers.length === 0) {
        throw new Error('No categorized answers available to import.');
      }

      const body = categorizedAnswers.map((answer: CategorizedAnswer) => ({
        resultId: answer.ResultId,
        questionId: answer.QuestionId,
        metadata: {
          categories: answer.Categories,
        },
      }));

      await api.post(`/results/updateResultMetadataImportingExcel`, body);

      addToast({
        type: 'success',
        title: 'Import Categories',
        description: 'Categories were imported successfully.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Update Metadata',
        description: 'Error when importing categories. Please try again.',
      });
    } finally {
      setShowButtonSubmit(false);
      setIsSelectedFile(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [addToast, categorizedAnswers, showButtonSubmit]);

  return (
    <>
      <h1>Import Categorized Answers</h1> <br />
      <BackButton />
      <Container>
        <ContentFile>
          <div className="App">
            <div className="upload-btn-wrapper">
              <input
                type="file"
                name="myfile"
                onChange={onChange}
                ref={fileInputRef}
              />
            </div>
            <br />
          </div>
        </ContentFile>
      </Container>
      <div hidden={!showButtonSubmit}>
        <table hidden={!isSeelectedFile}>
          <thead>
            <tr>
              <HeaderFile>ResultId</HeaderFile>
              <HeaderFile>Question Id</HeaderFile>
              <HeaderFile>Question Text</HeaderFile>
              <HeaderFile>Answer</HeaderFile>
              <HeaderFile>Categories</HeaderFile>
            </tr>
          </thead>
          <tbody>
            {categorizedAnswers?.map((t: CategorizedAnswer, index: number) => (
              <tr key={index}>
                <ColumnFile>{t.ResultId}</ColumnFile>
                <ColumnFile>{t.QuestionId}</ColumnFile>
                <ColumnFile>{t.QuestionText}</ColumnFile>
                <ColumnFile>{t.Answer}</ColumnFile>
                <ColumnFile>{t.Categories}</ColumnFile>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          hidden={errors.length === 0}
          style={{
            margin: '20px 10px 10px 10px',
            padding: '10px 10px 15px 10px',
            borderRadius: '8px',
            backgroundColor: '#fddede',
            color: '#c53030',
          }}
        >
          <>
            <h3>Errors:</h3>
            <ul>
              {errors.length > 0 &&
                errors.map(error => {
                  return (
                    <li style={{ marginLeft: '40px' }} key={error}>
                      {error}
                    </li>
                  );
                })}
            </ul>
          </>
        </div>
      </div>
      <DivButtonSubmit>
        <Button
          hidden={!showButtonSubmit}
          type="button"
          onClick={handleImportSubmit}
          marginRight="30px"
          width="200px"
          height="40px"
        >
          Submit
        </Button>
      </DivButtonSubmit>
    </>
  );
};

export default ImportFile;
