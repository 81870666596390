/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';

import { read, utils } from 'xlsx';

import { ColumnFile, Container, ContentFile, DivButtonSubmit } from './styles';
import api from '../../../services/api';

import Button from '../../../components/Button';

interface Sample {
  AccessId: string;
  AccessName: string;
  SurveyId: string;
  NewLink: string;
  LanguageId: string;
  Sample: string;
}

const ImportSample: React.FC = () => {
  const [sampleList, setSampleList] = useState<Sample[]>();
  const [isSeelectedFile, setIsSelectedFile] = useState(false);
  const [showButtonSubmit, setShowButtonSubmit] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [responseImport, setResponseImport] = useState([]);

  async function ValidationDataFile(data: Sample[]): Promise<string[]> {
    const errorsFromFile: string[] = [];

    data.forEach((element: Sample) => {
      if (Number.isNaN(parseInt(element.AccessId, 10))) {
        errorsFromFile.push('AccessId should be a number');
      }
      if (Number.isNaN(parseInt(element.SurveyId, 10))) {
        errorsFromFile.push('SurveyId should be a number');
      }
      if (Number.isNaN(parseInt(element.NewLink, 10))) {
        errorsFromFile.push('NewLink should be a number');
      }
      if (Number.isNaN(parseInt(element.LanguageId, 10))) {
        errorsFromFile.push('LanguageId should be a number');
      }
      if (Number.isNaN(parseInt(element.Sample, 10))) {
        errorsFromFile.push('Sample should be a number');
      }
      if (!element.AccessId) {
        errorsFromFile.push('AccessID is required');
      }
      if (!element.SurveyId) {
        errorsFromFile.push('SurveyId is required');
      }

      if (!element.NewLink && parseInt(element.NewLink, 10) !== 0) {
        errorsFromFile.push(
          'Number of links to be created is required. If don`t want to create a new link, fill with 0 (zero)',
        );
      }
      if (!element.LanguageId) {
        errorsFromFile.push(
          'Number of links to be created is required. If don`t want to create a new link, fill with 0 (zero)',
        );
      }
      if (!element.Sample) {
        errorsFromFile.push(
          'Number for column Sample is required. IF there`s no sample, fill with 0 (zero)',
        );
      }
    });

    return errorsFromFile;
  }

  const onChange = async (e: any): Promise<void> => {
    const file = e.target.files[0];
    if (file) {
      const f = await file.arrayBuffer();

      const wb = read(f);
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data = utils.sheet_to_json<Sample>(ws); // generate objects
      setIsSelectedFile(true);

      const errorsFromFile = await ValidationDataFile(data);
      const newDataFileWithAccessNames = await Promise.all(
        data.map(async (item: any) => {
          const newItem = await api
            .get(`/accesses/${item.AccessId}`)
            .then(response => {
              return {
                ...item,
                AccessName: response.data.name,
              };
            });
          return newItem;
        }),
      );

      setErrors(errorsFromFile);
      setSampleList(newDataFileWithAccessNames);
      setShowButtonSubmit(false);

      if (errorsFromFile.length === 0) {
        setShowButtonSubmit(true);
      }
    }
  };

  const handleImportSubmit = useCallback(() => {
    setShowButtonSubmit(!showButtonSubmit);
    try {
      api
        .post('accesses/importSampleFile', {
          data: sampleList,
        })
        .then(response => {
          setResponseImport(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  }, [sampleList, showButtonSubmit]);

  return (
    <>
      <h1>Import Samples for Accesses</h1> <br />
      <Container>
        <ContentFile>
          <div className="upload-btn-wrapper">
            <input type="file" name="myfile" onChange={onChange} />
          </div>
          <br />
        </ContentFile>
      </Container>
      <table hidden={!isSeelectedFile}>
        <thead>
          <tr>
            <th>Access ID</th>
            <th>Access Name</th>
            <th>SurveyId</th>
            <th>NewLinks to be created</th>
            <th>LanguageId</th>
            <th>Sample</th>
          </tr>
        </thead>

        <tbody>
          {sampleList?.map((t: Sample) => (
            <tr key={t.AccessId}>
              <ColumnFile>{t.AccessId}</ColumnFile>
              <ColumnFile>{t.AccessName}</ColumnFile>
              <ColumnFile>{t.SurveyId}</ColumnFile>
              <ColumnFile>{t.NewLink}</ColumnFile>
              <ColumnFile>{t.LanguageId}</ColumnFile>
              <ColumnFile>{t.Sample}</ColumnFile>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        hidden={errors.length === 0}
        style={{
          margin: '20px 10px 10px 10px',
          padding: '10px 10px 15px 10px',
          borderRadius: '8px',
          backgroundColor: '#fddede',
          color: '#c53030',
        }}
      >
        <>
          <h3>Errors:</h3>
          <ul>
            {errors.length > 0 &&
              errors.map((error, index) => {
                return (
                  <li style={{ marginLeft: '40px' }} key={index}>
                    {error}
                  </li>
                );
              })}
          </ul>
        </>
      </div>
      <DivButtonSubmit>
        <Button
          hidden={!showButtonSubmit}
          type="button"
          onClick={handleImportSubmit}
          width="200px"
          height="40px"
          marginLeft="100px"
        >
          Submit
        </Button>
      </DivButtonSubmit>
      <div hidden={showButtonSubmit || !isSeelectedFile || errors.length > 0}>
        <h2>Import result:</h2>
        <table hidden={!isSeelectedFile}>
          <thead>
            <tr>
              <th>Access ID</th>
              <th>Access Name</th>
              <th>Sample</th>
            </tr>
          </thead>

          <tbody>
            {responseImport.map((itemFb: any, index) => {
              return (
                <tr key={index}>
                  <ColumnFile>{itemFb.accessId}</ColumnFile>
                  <ColumnFile>{itemFb.name}</ColumnFile>
                  <ColumnFile>{itemFb?.sample}</ColumnFile>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ImportSample;
